<template>
  <div>
    <ys-page-list
        ref="table"
        :multiBtn="multiBtn"
        showContextMenu
        keyID="1444CF5B558EBC0F65EDCA854AE766D0"
        :params="params"
        action="/article/List"
        :tableHeight="tableHeight"
        @on-selection-change="checkItem"
        :headerColumns="headerColumns">
      <template slot="search">
        <FormItem label="关键词" prop="keyword">
          <Input placeholder="关键词" clearable style="width: 150px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
      <template slot="btn">
        <ButtonGroup>
          <Button type="primary" @click="add">新增</Button>
        </ButtonGroup>
      </template>
      <template slot="detail">
        <Detail :mid="detailID"></Detail>
      </template>
    </ys-page-list>
    <ys-modal v-model="modShow" :width="1000" title="新增/修改通知配置">
      <Edit :mid="detailID" @on-ok="getList"></Edit>
    </ys-modal>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js'
import Edit from "@/views/base/notice/manage/edit.vue";
import Detail from "@/views/base/notice/manage/detail.vue";

export default {
  name: 'manage',
  mixins: [pageList],
  components: {
    Edit,
    Detail
  },
  data() {
    return {
      params: {
        keyword: '',
      },
      modShow: false,
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 50, align: 'center'},
        {minWidth: 200, title: '通知标题', key: 'title', align: 'center', renderConfig: {type: 'gotoDetail'}},
        {width: 80, title: '所属类型', key: 'type', align: 'center', renderConfig: {type: 'baseData', parentName: '文章所属类型'}},
        {width: 150, title: '发布机构', key: 'orgName', align: 'center'},
        {width: 150, title: '所属分类', key: 'articleTypeName', align: 'center'},
        {width: 150, title: '标签', key: 'label', align: 'left', render: (h, params) => {
          const data = params.row?.label ? JSON.parse(params.row.label) : []
          return h('div', data.map(item => h('p', item)))
        }},
        {width: 200, title: '发布时间', key: 'publishedAt', align: 'center', },
        {width: 200, title: '创建时间', key: 'createdAt', align: 'center', },
        {width: 80, title: '创建人', key: 'createdUser', align: 'center',},
      ]
    }
  },
  computed: {
    multiBtn() {
      return [
        {
          children: [
            {
              type: 'detail',
              title: '查看详情',
              icon: 'md-eye',
              disabled: this.tableSelectItem.length !== 1
            },
            {
              click: () => this.modShow = true,
              title: '编辑',
              icon: 'md-create',
              disabled: this.tableSelectItem.length !== 1
            },
            {
              click: () => this.delItem('/article/Del', {id: this.tableSelectItem[0].id}),
              iconColor: '#ed4014',
              title: '删除',
              icon: 'md-trash',
              disabled: this.tableSelectItem.length !== 1
            },
          ]
        },
      ]
    },
  },
  mounted() {
    this.getList();
  },
  methods: {}
}
</script>

<style>

</style>