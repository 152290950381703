<template>
  <div>
    <Form ref="form" class="editForm twoPartsEditForm" :model="form" :rules="ruleValidate" :label-width="100" inline>
      <FormItem label="分类名称" prop="name" style="width: 100%;">
        <Input v-model="form.name" placeholder="分类名称"></Input>
      </FormItem>
      <FormItem label="上级分类" prop="pidList" style="width: 100%;">
        <Cascader :data="articleTypeList" v-model="form.pidList" clearable change-on-select @on-change="articleTypeChange"></Cascader>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" style="float: left;" :disabled="!mid" @click="del">删除</Button>
      <Button type="primary" @click="save">确定并保存</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>

<script>
import edit from '@/mixins/edit'
import {articleTypeList, articleTypeInfo} from "@/api/base";
import {getRecorderAryByDeep} from "ys-admin";

export default {
  name: 'typeEdit',
  components: {},
  mixins: [edit],
  props: {
    pid: {
      type: [String, Number],
      default: ''
    },
    orgId: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      ruleValidate: {
      //   smsType: [
      //     {required: true, type: 'number', message: '请选择所属分类', trigger: 'blur'}
      //   ],
      //   channel: [
      //     {required: true, type: 'number', message: '请选择渠道', trigger: 'blur'}
      //   ],
      },
      form: {
        id: '',
        orgId: '',
        name: '',
        pidList: [],
        pid: '',
      },
      articleTypeList: [],
    }
  },
  computed: {},
  async mounted() {
    this.form.orgId = this.orgId
    // 编辑
    if(this.mid) {
      await articleTypeInfo({id: this.mid}).then(res => {
        Object.keys(this.form).forEach((item) => {
          this.form[item] = res.data[item]
        })
      })
    }
    // 当前机构下分类list
    if (this.orgId) {
      await articleTypeList({orgId: this.orgId}).then(res => {
        this.articleTypeList = this.restructure(res?.data || [])
      })
    }
    // 携带过来的父,pid
    if (this.pid) {
      this.form.pid = this.pid
    }
    // 根据已有的pid, 寻找当前pid的路径
    this.form.pidList = getRecorderAryByDeep({targetData: this.articleTypeList, targetValue: this.form.pid})
    this.form.pid = this.form.pidList[this.form.pidList.length - 1]
  },
  methods: {
    articleTypeChange(item) {
      this.form.pid = item[item.length - 1] || ''
    },
    // 重构分类列表数据
    restructure(list) {
      return list.map(item => {
        item.value = item.id
        item.label = item.name
        item.children = item.children || []
        if(this.mid && item.id === this.mid) {
          item.disabled = true
        }
        if(item.children && item.children.length > 0) {
          this.restructure(item.children)
        }
        return item
      })
    },
    // 寻找节点数据
    findNode(list) {
      for(let i = 0;i < list.length;i++) {
        let item = list[i]
        if(item.id === this.mid) {
          return item
        }
        if(item?.children.length > 0) {
          return this.findNode(item.children)
        }
      }
    },
    del() {
      // 判断一下当前删除的分类下是否存在子级,有则不允许删除
      const nodeData = this.findNode(this.articleTypeList)
      if(nodeData?.children.length > 0) {
        this.$Message.warning('该节点下存在子节，请先删除子节点！');
        return
      }
      this.$del('/article/type/Del', {id: this.mid}).then(res => {
        if (res.code === 200) {
          this.close();
          this.$Notice.success({
            title: '提示',
            desc: '操作成功！'
          });
          this.$emit('on-ok');
        }
      })
    },
    save() {
      this.ok('/article/type/AddOrEdit');
    },
  }
}
</script>

<style lang="less" scoped>

</style>


