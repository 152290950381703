<template>
  <div style="height: 667px;overflow: auto;">
    <div  v-html="content"></div>
  </div>
</template>

<script>
export default {
  name: 'mobilePreview',
  components: {},
  mixins: [],
  props: {
    content: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },
  computed: {},
  async mounted() {},
  methods: {}
}
</script>

<style lang="less" scoped>

</style>


