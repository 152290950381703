import {del, post, get} from 'ys-admin'

/**
 * 获取十大项
 * @returns {Object}
 */
export const getCategory = function (params) {
  return get('/standard/dictCategory/List',params)
};
/**
 * 绑定中台标准
 * @returns {Object}
 */
export const bindRemoteStandard = function (params) {
  return post('/standard/remote/Bind',params)
};

/**
 * 获取检查标准列表
 * @returns {Object}
 */
export const getStandardList = function (params) {
  return get('/standard/List',params)
};
/**
 * 获取检查标准详情
 * @returns {Object}
 */
export const apiLogsInfo = function (params) {
  return get('/apilogs/Info',params)
};

/**
 * 获取检查标准详情
 * @returns {Object}
 */
export const getStandardInfo = function (params) {
  return get('/standard/GetInfo',params)
};

/**
 * 获取评分表
 * @param {Object} params
 * @returns {Object}
 */
export const getCategoryDict = function (params) {
  return get('/standard/dict/List', params)
};

/**
 * 获取评pin
 * @param {Object} params
 * @returns {Object}
 */
export const getCategoryDictItem = function (params) {
  return get('/standard/dictItem/List', params)
};

/**
 * 获取评分大项
 * @param {Object} params
 * @returns {Object}
 */
export const getDictDetailInfo = function (params) {
  return get('/standard/dictDetail/GetInfo', params)
};

/**
 * 获取评分大项
 * @param {Object} params
 * @returns {Object}
 */
export const getDictReasonInfo = function (params) {
  return get('/standard/dictReason/GetInfo', params)
};

/**
 * 获取站点配置详情
 * @param {Object} params
 * @returns {Object}
 */
export const siteSetInfo = function (params) {
  return get('/platformConfig/GetInfo', params)
};
/**
 * 获取模板详情
 * @param {Object} params
 * @returns {Object}
 */
export const templateInfo = function (params) {
  return get('/template/GetInfo', params)
};
/**
 * 获取总项项详情
 * @param {Object} params
 * @returns {Object}
 */
export const getDictCategoryInfo = function (params) {
  return get('/standard/dictCategory/GetInfo', params)
};

/**
 * 获取表项详情
 * @param {Object} params
 * @returns {Object}
 */
export const getDictInfo = function (params) {
  return get('/standard/dict/GetInfo', params)
};

/**
 * 获取大项详情
 * @param {Object} params
 * @returns {Object}
 */
export const getItemInfo = function (params) {
  return get('/standard/dictItem/GetInfo', params)
};

/**
 * 删除大项
 * @param {Object} params
 * @returns {Object}
 */
export const delItem = function (params) {
  return del('/standard/dictItem/Remove', params)
};
/**
 * 删除总项
 * @param {Object} params
 * @returns {Object}
 */
export const delCategory = function (params) {
  return del('/standard/dictCategory/Remove', params)
};
/**
 * 删除表项
 * @param {Object} params
 * @returns {Object}
 */
export const delDict = function (params) {
  return del('/standard/dict/Remove', params)
};

/**
 * 获取菜单
 * @param {Object} params
 * @returns {Object}
 */
export const getMenuList = function (params) {
  return get('/menu/ListAll', params)
};
/**
 * 删除菜单
 * @param {Object} params
 * @returns {Object}
 */
export const deltMenu = function (params) {
  return del('/menu/Del', params)
};
/**
 * 查询任务类型
 * @param {Object} params
 * @returns {Object}
 */
export const getTaskTypeList = function (params) {
  return get('/task/List', params)
};

/**
 * 获取角色详情
 * @param {Object} params
 * @returns {Object}
 */
export const getRoleInfo = function (params) {
  return get('/permission/role/GetInfo', params)
};

/**
 * 获取数据字典详情
 * @param {Object} params
 * @returns {Object}
 */
export const getGlobalDictInfo = function (params) {
  return get('/globalDict/GetInfo', params)
};

/**
 * 获取权限集列表
 * @param {Object} params
 * @returns {Object}
 */
export const getPermissionList = function (params) {
  return get('/permission/rule/List', params)
};

/**
 * 获取权限集列表
 * @param {Object} params
 * @returns {Object}
 */
export const runOnce = function (params) {
  return post('/taskScheduler/RunOnce', params)
};
/**
 * 分发列表配置
 * @param {Object} params
 * @returns {Object}
 */
export const pageListSetGlobal = function (params) {
  return post('/page-list/SetGlobal', params)
};

/**
 * 切换2个菜单顺序
 * @param {Object} params
 * @returns {Object}
 */
export const exchangeIndex = function (params) {
  return post('/menu/ExchangeIndex', params)
};
/**
 * 更新数据项
 * @param {Object} params
 * @returns {Object}
 */
export const saveBaseData = function (params) {
  return post('/globalDict/Edit', params)
};

/**
 * 获取全局配置信息
 * @returns {Object}
 */
export const getSystemConfig = function () {
  return get('/systemConfig/GetInfo', {})
};

/**
 * 禁用AppId
 * @returns {Object}
 */
export const closeSystemConfig = function () {
  return post('/systemConfig/DisableAppId', {})
};

/**
 * 启用AppId
 * @returns {Object}
 */
export const openSystemConfig = function () {
  return post('/systemConfig/EnableAppId', {})
};

/**
 * 生成AppId
 * @returns {Object}
 */
export const genSystemConfig = function () {
  return post('/systemConfig/GenAppId', {})
};
/**
 * 查看各端系统标题
 * @returns {Object}
 */
export const getTitleInfo = function () {
  return get('/systemConfig/GetSystemInfo', {})
};

/**
 * 查询表头配置详情
 * @returns {Object}
 */
export const genPageListInfo = function (params) {
  return get('/page-list/Info', params)
};
/**
 * 查询表头配置详情
 * @returns {Object}
 */
export const genPageListInfoById = function (params) {
  return get('/page-list/InfoById', params)
};

/**
 * 查询表头筛选项详情
 * @returns {Object}
 */
export const getFilterInfo = function (params) {
  return get('/filterItem/GetInfo', params)
};

/**
 * 查询角色详情
 * @returns {Object}
 */
export const roleInfo = function (params) {
  return get('/permission/role/GetInfo', params)
};

/**
 * 查询短信配置详情
 * @returns {Object}
 */
export const smsInfo = function (params) {
  return get('/sms/config/Info', params)
};

/**
 * 查询短信模板配置详情
 * @returns {Object}
 */
 export const smsTemplateInfo = function (params) {
  return get('/sms/template/Info', params)
};

/**
 * 查询行政区域详情
 * @returns {Object}
 */
 export const areaInfo = function (params) {
  return get('/area/Info', params)
};

/**
 * 行政区域删除
 * @returns {Object}
 */
 export const areaDel = function (params) {
  return del('/area/Del', params)
};

/**
 * 机构短信配置详情
 * @returns {Object}
 */
 export const smsOrgConfigInfo = function (params) {
  return get('/sms/orgConfig/Info', params)
};

/**
 * 文章详情
 * @returns {Object}
 */
 export const articleInfo = function (params) {
  return get('/article/Info', params)
};

/**
 * 文章分类树
 * @returns {Object}
 */
 export const articleTypeList = function (params) {
  return get('/article/type/List', params)
};

/**
 * 文章分类详情
 * @returns {Object}
 */
 export const articleTypeInfo = function (params) {
  return get('/article/type/Info', params)
};

/**
 * 获取智能设备详情
 * @returns {Object}
 */
 export const hardHatGetBySeqNo = function (params) {
  return get('/hardHat/GetBySeqNo', params)
};

/**
 * 获取智能设备参数
 * @returns {Object}
 */
 export const hardHatGetConfigBySeqNo = function (params) {
  return get('/hardHat/GetConfigBySeqNo', params)
};

/**
 * 修改智能设备启停状态
 * @returns {Object}
 */
 export const setEnabledStateBySeqNo = function (params) {
  return post('/hardHat/SetEnabledStateBySeqNo', params)
};

/**
 * 智能设备绑定服务机构
 * @returns {Object}
 */
 export const hardHatBindOrgBySeqNo = function (params) {
  return post('/hardHat/BindOrgBySeqNo', params)
};

/**
 * 智能设备绑定服务机构
 * @returns {Object}
 */
 export const hardHatUnbindOrgBySeqNo = function (params) {
  return post('/hardHat/UnbindOrgBySeqNo', params)
};

/**
 * 获取智能设备与任务下专家绑定
 * @returns {Object}
 */
 export const hardHatBindCheckUserBySeqNo = function (params) {
  return post('/hardHat/BindCheckUserBySeqNo', params)
};

/**
 * 获取智能设备定位轨迹
 * @returns {Object}
 */
 export const hardHatQueryLocusBySeqNo = function (params) {
  return get('/hardHat/QueryLocusBySeqNo', params)
};

/**
 * 获取智能设备上传的图片
 * @returns {Object}
 */
 export const hardHatQueryPhotosBySeqNo = function (params) {
  return get('/hardHat/QueryPhotosBySeqNo', params)
};

/**
 * 获取智能设备上传的视频
 * @returns {Object}
 */
 export const hardHatQueryVideosBySeqNo = function (params) {
  return get('/hardHat/QueryVideosBySeqNo', params)
};