<template>
  <div style="border: 1px solid #ccc;z-index: 2;">
    <Toolbar
      style="border-bottom: 1px solid #ccc"
      :editor="editor"
      :defaultConfig="toolbarConfig"
      :mode="mode"
    />
    <Editor
      style="height: 400px; overflow-y: hidden;"
      v-model="html"
      :defaultConfig="editorConfig"
      :mode="mode"
      @onCreated="onCreated"
    />
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
// import { DomEditor } from '@wangeditor/editor'

export default {
  components: { Editor, Toolbar },
  props: {
    editorHtml: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      editor: null,
      html: '',
      // 菜单栏配置
      toolbarConfig: {
        // 如果仅仅想排除掉某些菜单，其他都保留，可以使用 excludeKeys 来配置。
        excludeKeys: [
          "group-video", "codeBlock"
        ],
        insertKeys: {
          index: 22, // 自定义插入的位置
          keys: ['uploadAttachment'], // “上传附件”菜单
        },
      },
      // 文本编辑器配置
      editorConfig: {
        // 占位符
        placeholder: '请输入内容...',
        // 在编辑器中，点击选中“附件”节点时，要弹出的菜单
        hoverbarKeys: {
          attachment: {
            menuKeys: ['downloadAttachment'], // “下载附件”菜单
          },
        },
        MENU_CONF: {
          'uploadImage': { // 上传图片配置
            server: '/web/resource/UploadFile', // 上传地址
            fieldName: 'file', // 上传文件键值
            headers: { // 请求头配置
              token: '',
              orgId: ''
            },
            // 上传成功后，用户自定义插入文件
            customInsert(res, insertFn) {
              const {url} = res.data
              insertFn(url)
            }
          },
          // “上传附件”菜单的配置
          'uploadAttachment': {
            server: '/web/resource/UploadFile', // 服务端地址
            timeout: 5 * 1000, // 5s
            fieldName: 'file',
            meta: {}, // 请求时附加的数据
            metaWithUrl: true, // meta 拼接到 url 上
            headers: {
              Accept: 'text/x-json',
              token: '',
              orgId: ''
            },
            maxFileSize: 10 * 1024 * 1024, // 10M
            // 上传成功后，用户自定义插入文件
            customInsert(res, file, insertFn) {
              const {url} = res.data
              if (!url) throw new Error(`url is empty`)
              // 插入附件到编辑器
              insertFn(`customInsert-${file.name}`, url)
            },
            onBeforeUpload(file) {
              return file // 上传 file 文件
              // return false // 会阻止上传
            },
            // onProgress(progress) {
            //   console.log('onProgress', progress)
            // },
            // onSuccess(file, res) {
            //   console.log('onSuccess', file, res)
            // },
            onFailed(file, res) {
              alert(res.message)
              // console.log('onFailed', file, res)
            },
            onError(file, err) {
              alert(err.message)
              // console.error('onError', file, err, res)
            },
          }
        }
      },
      mode: 'default', // default or 'simple'
    }
  },
  computed: {
    orgId() {
      return sessionStorage.getItem('orgId')
    },
    ...mapGetters(['token',])
  },
  created() {},
  mounted() {
    this.editorConfig.MENU_CONF['uploadImage'].headers.token = this.token
    this.editorConfig.MENU_CONF['uploadImage'].headers.orgId = this.orgId
    this.editorConfig.MENU_CONF['uploadAttachment'].headers.token = this.token
    this.editorConfig.MENU_CONF['uploadAttachment'].headers.orgId = this.orgId
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
      this.editorHtml && this.editor && this.setHtml()
    },
    setHtml() {
      this.editor.setHtml(this.editorHtml)
    },
    getHtml() {
      return this.editor.getHtml()
    },
  },
  watch: {
    'editorHtml'() {
      this.editorHtml && this.editor && this.setHtml()
    }
  },
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  }
}
</script>

<style src="@wangeditor/editor/dist/css/style.css"></style>
<style>

</style>