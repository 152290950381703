<template>
  <div>
    <Form ref="form" class="editForm twoPartsEditForm" :model="form" :rules="ruleValidate" :label-width="100" inline>
      <FormItem label="标题" prop="title" style="width: 100%;">
        <Input v-model="form.title" placeholder="标题"></Input>
      </FormItem>
      <FormItem label="标签" prop="label">
        <Select v-model="form.label" filterable multiple allow-create @on-create="lableCreate" placeholder="手动输入后回车创建">
          <Option v-for="item in labelList" :value="item.value" :key="item.value">{{ item.label }}</Option>
        </Select>
      </FormItem>
      <FormItem label="所属类型" prop="type">
        <Select v-model="form.type" clearable :disabled="isdisabled" @on-change="typeChange" transfer placeholder="所属类型">
          <Option v-for="item in baseData['文章所属类型']" :value="item.value" :key="item.value">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="发布机构" prop="orgId" style="width: 100%;" v-if="form.type === 1">
        <Input v-model="form.orgName" disabled placeholder="发布机构" style="width: 90%;"></Input>
        <Button type="primary" @click="orgFlag=true" style="width: 10%;">选择</Button>
      </FormItem>
      <FormItem label="分类" prop="articleTypeIdList">
        <Cascader :data="articleTypeList" v-model="form.articleTypeIdList" clearable change-on-select @on-change="articleTypeChange" placeholder="分类" style="display: inline-block; width: 70%;"></Cascader>
        <ButtonGroup style="display: inline-block; width: 27%;">
          <Button type="primary" icon="md-create" :disabled="!form.articleTypeId" @click="articleTypeEditFn('edit')" style="width: 50%;"></Button>
          <Button type="primary" icon="md-add" @click="articleTypeEditFn('add')" style="width: 50%;"></Button>
        </ButtonGroup>
      </FormItem>
      <FormItem label="发布时间" prop="publishedAt">
        <ys-date-picker type="date" v-model="form.publishedAt" style="width: 100%" :placeholder="'发布时间'"/>
      </FormItem>
      <FormItem label="内容" prop="desc" style="width: 100%">
        <editor ref="editor" :editorHtml="form.content"></editor>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="preview">手机预览</Button>
      <Button type="primary" @click="save">确定并保存</Button>
      <Button @click="close">取消</Button>
    </div>
    <ys-modal v-model="orgFlag" :width="1200" title="选择机构">
      <OrgComponent :limit="1" :oType="1" :oTypeListValue="[1]" @on-ok="getOrgData"></OrgComponent>
    </ys-modal>
    <ys-modal v-model="typeFlag" :width="500" title="新增/编辑分类">
      <articleType-Edit :orgId="form.orgId" :mid="form.articleTypeId" :pid="form.pid" @on-ok="getArticleTypeList"></articleType-Edit>
    </ys-modal>
    <ys-modal v-model="previewFlag" :width="375" title="预览">
      <mobile-preview :content="form.content"></mobile-preview>
    </ys-modal>
  </div>
</template>

<script>
import edit from '@/mixins/edit'
import editor from '@/views/base/notice/manage/editor/index.vue'
import articleTypeEdit from '@/views/base/notice/manage/articleTypeEdit.vue'
import mobilePreview from '@/views/base/notice/manage/detail/mobilePreview.vue'
import OrgComponent from "_c/org";
import {articleInfo, articleTypeList} from "@/api/base";
import {getRecorderAryByDeep} from "ys-admin";

export default {
  name: 'configureEdit',
  components: {
    editor,
    OrgComponent,
    articleTypeEdit,
    mobilePreview,
  },
  mixins: [edit],
  props: {},
  data() {
    return {
      ruleValidate: {
        // smsType: [
        //   {required: true, type: 'number', message: '请选择所属分类', trigger: 'blur'}
        // ],
        // channel: [
        //   {required: true, type: 'number', message: '请选择渠道', trigger: 'blur'}
        // ],
        // status: [
        //   {required: true, type: 'number', message: '请选择状态', trigger: 'blur'}
        // ],
        // configContent: [
        //   {required: true, message: '请填写相关配置', trigger: 'blur'}
        // ],
      },
      form: {
        id: '',
        orgId: 0,
        orgName: '',
        title: '',
        label: '',
        type: 1,
        articleTypeId: '',
        articleTypeIdList: [],
        content: '',
        publishedAt: new Date(),
      },
      labelList: [],
      articleTypeList: [],
      pid: '',
      isdisabled: true,
      typeFlag: false,
      orgFlag: false,
      previewFlag: false,
    }
  },
  computed: {},
  async mounted() {
    if (this.mid) {
      await articleInfo({id: this.mid}).then(res => {
        Object.keys(this.form).forEach((item) => {
          this.form[item] = res.data[item]
        })
        // 标签处理
        this.form.label = JSON.parse(this.form.label)
        this.form.label.forEach(item => {
          this.labelList.push({
            value: item,
            label: item
          })
        })
        // this.form.pidList = getRecorderAryByDeep({targetData: this.articleTypeList, targetValue: this.form.pid})
        // this.form.pid = this.form.pidList[this.form.pidList.length - 1]
      })
      await this.getArticleTypeList()
    }
  },
  methods: {
    lableCreate (val) {
      this.labelList.push({
        value: val,
        label: val
      });
    },
    typeChange(value) {
      if (value === 2) {
        this.form.orgId = 0;
        this.form.orgName = '';
      }
      this.form.articleTypeId = ''
      this.form.articleTypeIdList = []
      this.getArticleTypeList()
    },
    getOrgData(data) {
      this.form.orgName = data[0].iname
      this.form.orgId = data[0].id
      this.form.articleTypeId = ''
      this.form.articleTypeIdList = []
      this.getArticleTypeList()
    },
    // 分类列表
    getArticleTypeList() {
      // if(!this.form.orgId) return
      articleTypeList({orgId: this.form.orgId}).then(res => {
        this.articleTypeList = this.restructure(res?.data || [])
        // this.articleTypeList = res?.data || []
        // 使用已有分类id, 寻找当前分类id所在的路径
        if(this.form.articleTypeId) {
          this.form.articleTypeIdList = getRecorderAryByDeep({targetData: this.articleTypeList, targetValue: this.form.articleTypeId})
        }
      })
    },
    // 重构分类列表数据
    restructure(list) {
      return list.map(item => {
        item.value = item.id
        item.label = item.name
        item.children = item.children || []
        if(item.children && item.children.length > 0) {
          this.restructure(item.children)
        }
        return item
      })
    },
    articleTypeChange(item) {
      this.form.articleTypeId = item[item.length - 1] || ''
    },
    articleTypeEditFn(mode) {
      if(this.form.type === 1 && !this.form.orgId) {
        this.$Message.warning('请先选择发布机构！');
        return
      }
      // 新增，清空所选
      if(mode === 'add') {
        // 新增父/新增子
        this.form.pid = this.form.articleTypeId || ''
        this.form.articleTypeId = ''
        this.form.articleTypeIdList = []
      }
      if(mode === 'edit') {
        this.form.pid = ''
      }
      this.typeFlag = true
    },
    preview() {
      this.form.content = this.$refs.editor.getHtml()
      this.previewFlag = true
    },
    save() {
      this.form.content = this.$refs.editor.getHtml()
      this.ok('/article/AddOrEdit');
    },
  }
}
</script>

<style lang="less" scoped>

</style>


