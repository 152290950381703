import {mapGetters} from 'vuex'

export default {
  name: 'baseInfoMixins',
  props: {
    mid: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      chartLoading: false,
      query: null,
    }
  },
  computed: {
    ...mapGetters(['height']),
    baseInfoHeight() {
      return this.query ? this.height - 268 : this.height - 173
    },
  },
  mounted() {
    this.query = this.$router.currentRoute.params.id
  },
  methods: {},
};
