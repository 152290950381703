<template>
  <div :style="{'height':height-193+'px','overflow-y':'auto'}" class="overflowY">
    <ys-cell-group :gutter="2">
      <ys-cell title="通知标题" :label="detail.title" block/>
      <ys-cell title="创建人" :label="detail.createdUser"/>
      <ys-cell title="创建时间" :label="detail.createdAt"/>
      <ys-cell title="所属类型" :label="detail.type|getBaseDataName('文章所属类型')"/>
      <ys-cell title="发布机构" :label="detail.orgName"/>
      <ys-cell title="所属分类" :label="detail.articleTypeName"/>
      <ys-cell title="标签" :label="(detail.label ? JSON.parse(detail.label) : []).join('，')"/>
      <ys-cell title="发布时间" :label="detail.publishedAt"/>
    </ys-cell-group>
  </div>
</template>

<script>
import baseInfo from "@/mixins/baseInfo";

export default {
  name: "baseInfo",
  props: {detail: Object},
  mixins: [baseInfo],
  mounted() {
  },
  methods: {}
}
</script>

<style scoped>

</style>